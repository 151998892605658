import React from "react"
import { Box } from "rebass"

export const Content = props => (
    <Box
        sx={{
            fontSize: 2,
            maxWidth: "100%",
            lineHeight: "1.85",
            color: "text",
            fontFamily: "body",

            p: {
                fontSize: "1em",
                marginBottom: "1.3em",
                height: "auto",
                lineHeight: "1.45",
                ":last-of-type": {
                    marginBottom: "8em"
                }
            },

            h1: {
                fontSize: 6,
                marginTop: 0,
                padding: ".5em 0 !important",
            },

            h2: {
                fontSize: 5,
            },

            h3: {
                fontSize: 4,
            },

            h4: {
                fontSize: 3,
            },

            h5: {
                fontSize: 2,
            },

            h6: {
                fontSize: 1,
            },

            "h1, h2, h3, h4": {
                margin: "1.414em 0 0.5em",
                fontWeight: "bold",
                color: "heading",
                lineHeight: "1.42",
            },

            small: { fontSize: "0.707em" },
            "img, canvas, iframe, video, svg, select, textarea": {
                maxWidth: "100%",
            },

            div: { width: "100%" },
            "div img": { width: "100%" },

            "blockquote p": {
                fontSize: "1.5em",
                fontStyle: "italic",
                margin: "1em auto 1em",
                maxWidth: "10em",
            },

            li: { marginLeft: "2em" },

            "pre, code": { fontFamily: 'Menlo, Monaco, "Courier New", monospace' },

            pre: {
                backgroundColor: "#fafafa",
                fontSize: "0.8em",
                overflowX: "scroll",
                padding: "1.125em",
            },

            "a, a:visited": { color: "primary" },
            "a:hover, a:focus, a:active": { color: "#2980b9" },
        }}
        {...props}
    />
)
