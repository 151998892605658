import React from "react"
import { Flex } from "rebass"
import * as Style from "./DynamicPage.styles"

function DynamicPage({ html }) {
  return (<Flex>
    <Style.Content dangerouslySetInnerHTML={{ __html: html }} />
  </Flex>)
}

export default DynamicPage