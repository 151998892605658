import BaseLayout from "@components/BaseLayout"
import { WidthLimitation } from "@components/Content"
import DynamicPageComponent from "@components/DynamicPage"
import Hero from "@components/Hero"
import Seo from "@components/Seo"
import { graphql } from "gatsby"
import React from "react"

function DynamicPage({ data }) {
  const {
    markdownRemark: {
      frontmatter: { title, keywords },
      html,
    },
  } = data

  return (
    <BaseLayout hero={<Hero>{title}</Hero>}>
      <Seo title={title} keywords={keywords} />
      <WidthLimitation flexDirection="column" margin="0 auto">
        <DynamicPageComponent title={title} html={html} />
      </WidthLimitation>
    </BaseLayout>
  )
}

export const query = graphql`
  query DynamicPage($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        keywords
      }
      fields {
        slug
      }
      html
      timeToRead
    }
  }
`

export default DynamicPage
